<template>
  <div class="mx-auto max-w-6xl">
    <ClearCacheMeta v-if="bannerError" />
    <Swiper class="-m-4" :show-arrows="false">
      <template #items>
        <div
          v-for="banner in banners"
          :key="banner.entityId"
          class="w-full shrink-0 snap-start overflow-hidden rounded-xl p-2 md:w-1/2"
        >
          <BannerListItem
            :banner="banner"
            :primary="true"
            :hover-zoom="false"
          />
        </div>
      </template>
    </Swiper>
  </div>
</template>
<script setup lang="ts">
import { useBannerStore } from '~/stores/banner';
import { computed } from 'vue';

const bannerStore = useBannerStore();

if (!bannerStore.simpleBanners.length) {
  bannerStore.getSimpleBanners();
}

const banners = computed(() =>
  bannerStore.getNonHiddenSimpleBanners.filter(
    (banner) => banner.showOnStartpage && banner.location === 'TOP'
  )
);

let bannerError = false;

if (process.server) {
  const { error: simpleBannersError } = await bannerStore.getSimpleBanners();
  if (simpleBannersError.value) {
    bannerError = true;
  }
} else if (!bannerStore.simpleBanners.length) {
  bannerStore.getSimpleBanners();
}
</script>
